/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import type { DropResult } from '@hello-pangea/dnd'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { produce } from 'immer'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import ContentAuthors from 'components/ContentAuthors'
import PublishingYear from 'components/course_tile/PublishingYear'
import WideCourseTile from 'components/course_tile/WideCourseTile'
import EditableText from 'components/editable/EditableText'
import mutateContentItem from 'components/editable/mutateContentItem'
import useListMutation from 'components/editable/useListMutation'
import { ModuleTile } from 'components/publishing/ModuleTile'
import PageContainer from 'components/react_components/PageContainer'
import TargetAudiences from 'components/TargetAudiences'
import { useList, useListEntries } from 'hooks/data/lists'
import type { CourseModule } from 'hooks/data/modules'
import { useActiveUser } from 'hooks/data/user'
import useUrls from 'hooks/useUrls'
import { reorder } from 'utils/listUtils'
import { validateTitle as schema } from 'utils/validation'

import CaseTile from './cases/CaseTile'

export default function ListDetail() {
    const queryClient = useQueryClient()
    const mutateModule = (module, changes) => {
        const modelKey = module.model.toLowerCase()
        queryClient.setQueriesData(['ListEntry', 'list'], oldEntries =>
            produce(oldEntries, (draft: any) => {
                const oldEntry = draft?.find(entry => entry[modelKey]?.id === module.id)
                if (!oldEntry) {
                    return
                }
                const index = draft.indexOf(oldEntry)
                draft[index][modelKey] = { ...oldEntry[modelKey], ...changes }
            })
        )
    }
    const patchList = useListMutation()
    const patchModule = useMutation({
        onMutate: ({ module, changes }: { module: CourseModule; changes: Partial<CourseModule> }) => {
            if (Object.values(changes).some(v => v instanceof File)) {
                return
            }
            mutateModule(module, changes)
        },
        mutationFn: ({ module, changes }) => mutateContentItem(module.apiURL, changes),
        onSuccess: response => {
            mutateModule({ id: response.id, model: response.model }, response)
        }
    })
    const { id } = useParams<{ id: string }>()
    const { data: user } = useActiveUser()
    const { data: list } = useList(Number(id))
    const { data } = useListEntries({ list: list?.id }, Boolean(list))
    // Drag and drop flickers without the extra state variable :-(
    const [listEntries, setListEntries] = useState([])
    useEffect(() => setListEntries(data), [data])
    const theme = useTheme()
    const urls = useUrls()
    const userIsAllowedToChangeList = user && list?.user === user.id

    const { mutate: reorderListEntries } = useMutation({
        onMutate: (result: DropResult) => {
            const newListEntries = reorder(listEntries, result.source.index, result.destination.index)
            queryClient.setQueryData(['ListEntry', 'list', { list: list.id }], newListEntries)
            setListEntries(newListEntries)
        },
        mutationFn: result => {
            const entry = listEntries[result.source.index]
            return axios.post(`${urls['lists:listentryDetail'](entry.id)}moveTo/`, { to: result.destination.index })
        }
    })

    if (!list || !listEntries) {
        return null
    }

    function renderListEntry(entry, index) {
        let component
        let key

        if (entry.case) {
            key = `${entry.case.model}_${entry.case.id}`
            component = <CaseTile theCase={entry.case} disabled={entry.disabled} />
        } else if (entry.course) {
            key = `${entry.course.model}_${entry.course.id}`
            component = <WideCourseTile course={entry.course} userIsAuthenticated disabled={entry.disabled} />
        } else {
            const module =
                entry.textcoursemodule ||
                entry.pdfcoursemodule ||
                entry.questionnairecoursemodule ||
                entry.surveycoursemodule ||
                entry.viewercoursemodule ||
                entry.videocoursemodule ||
                entry.lecture

            key = `${module.model}_${module.id}`
            component = <ModuleTile module={module} disabled={entry.disabled} triggerMutation={patchModule.mutate} />
        }

        return (
            <Draggable key={key} draggableId={key} index={index} isDragDisabled={!userIsAllowedToChangeList}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style, marginBottom: theme.spacing(2) }}
                    >
                        {component}
                    </div>
                )}
            </Draggable>
        )
    }

    function onDragEnd(result) {
        if (!result.destination) {
            return
        }
        reorderListEntries(result)
    }

    return (
        <PageContainer fullWidth>
            <Box component="header" mb={4}>
                <Typography variant="overline" gutterBottom>
                    {gettext('List')}
                </Typography>
                <Box display="flex" alignItems="center" mb={4}>
                    <Link to="/lists">
                        <IconButton style={{ color: 'inherit' }} edge="start" size="large">
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                    <EditableText
                        contentItem={list}
                        triggerMutation={patchList.mutate}
                        name="title"
                        defaultValue={list.title}
                        validationSchema={schema}
                    >
                        <Typography variant="h1">{list.title}</Typography>
                    </EditableText>
                </Box>
                <PublishingYear contentItem={list} triggerMutation={patchList.mutate} />
                <ContentAuthors
                    contentItem={list}
                    triggerMutation={patchList.mutate}
                    name="listAuthors"
                    label={gettext('List Author(s)')}
                />
                <TargetAudiences contentItem={list} triggerMutation={patchList.mutate} />
            </Box>

            <Box component="main" display="flex" flexDirection="column" gap={theme.spacing(2)}>
                <Typography gutterBottom>{list.longDescription}</Typography>
                {userIsAllowedToChangeList && (
                    <Typography
                        style={{
                            opacity: 0.5
                        }}
                    >
                        {gettext('Drag items to change the order')}
                    </Typography>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {provided => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {listEntries.map(renderListEntry)}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </PageContainer>
    )
}
