/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import trim from 'lodash/trim'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, Navigate, Route, useLocation, Routes } from 'react-router-dom'

import AddCarousel from 'components/AddCarousel'
import { BookmarkedCoursesGrid, LastSeenCoursesGrid, PurchasedCoursesGrid } from 'components/CourseGrid'
import EditCarousel from 'components/EditCarousel'
import ListDetail from 'components/ListDetail'
import ListGrid from 'components/ListGrid'
import MaterialRoot from 'components/materialTheme/MaterialRoot'
import SingleCarousel from 'components/SingleCarousel'

import CoursesOverview from './CoursesOverview'

function ScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        const contentMain = document.getElementById('content-main')
        if (contentMain) {
            contentMain.scrollTo(0, 0)
        }
    }, [pathname])
    return null
}

const basename = `/${window.location.pathname.split('/').filter(Boolean)[0]}`

const root = createRoot(document.getElementById('root'))

function FallbackRoute() {
    const location = useLocation()
    if (location.hash) {
        return <Navigate to={location.pathname + trim(location.hash.replace('#', ''), '/')} />
    }
    return <CoursesOverview />
}

root.render(
    <Router basename={basename}>
        <MaterialRoot seed="courses_overview">
            <ScrollToTop />
            <Routes>
                <Route path="/edit_carousel/:id" element={<EditCarousel />} />
                <Route path="/add_carousel/:index" element={<AddCarousel />} />
                <Route path="/single_carousel/:id" element={<SingleCarousel />} />
                <Route path="/bookmarked/" element={<BookmarkedCoursesGrid />} />
                <Route path="/purchased/" element={<PurchasedCoursesGrid />} />
                <Route path="/lastseen/" element={<LastSeenCoursesGrid />} />
                <Route path="/lists/" element={<ListGrid />} />
                <Route path="/lists/:id" element={<ListDetail />} />
                <Route path="/" element={<FallbackRoute />} />
            </Routes>
        </MaterialRoot>
    </Router>
)
